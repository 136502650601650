import axios from "axios";
import {RobotMowerItem} from "@/types/RoboMowerTypes";

class InternalRoboMowerService {
    async loadAllRoboMowers(lawnArea: number): Promise<RobotMowerItem[]> {
        const mowers = axios.get('https://lawncalculator-backend.einhell-live.de/api/v1/find')
        // const mowers = axios.get('http://localhost:3080/api/v1/find')
            .then(response => {
                const filteredMower = this.filterMowersByArea(lawnArea, response.data)
                return filteredMower
            })
            .catch(error => {
                console.log('There was an Error loading Robo Mower', error)
                return []
            })

        return mowers
    }

    filterMowersByArea(lawnArea: number, mower: RobotMowerItem[]): RobotMowerItem[] {
        const filteredMower: RobotMowerItem[] = []

        mower.forEach((item) => {
            if (typeof item.lawnArea !== "number") {
                if (lawnArea <= 350 && parseInt(item.lawnArea.value.toString()) <= 350) {
                    filteredMower.push(item)
                }

                if ((lawnArea > 350 && lawnArea <=500) && (parseInt(item.lawnArea.value.toString()) > lawnArea && parseInt(item.lawnArea.value.toString()) <= 500)) {
                    filteredMower.push(item)
                }

                if ((lawnArea > 500 && lawnArea <=750) && (parseInt(item.lawnArea.value.toString()) > lawnArea && parseInt(item.lawnArea.value.toString()) <= 750)) {
                    filteredMower.push(item)
                }

                if ((lawnArea > 750 && lawnArea <=900) && (parseInt(item.lawnArea.value.toString()) > lawnArea && parseInt(item.lawnArea.value.toString()) <= 900)) {
                    filteredMower.push(item)
                }

                if (lawnArea > 900 && parseInt(item.lawnArea.value.toString()) > 900) {
                    filteredMower.push(item)
                }
            }

        })

        return filteredMower
    }
}

export const RoboMowerService = new InternalRoboMowerService()
