import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/template.scss'
import eVueComponents from "@shared/e-vue-components";
import "@shared/e-vue-components/dist/einhell.theme.css"

const mountingElem = document.getElementById('lawn-calculator')
const environment = mountingElem && mountingElem.dataset.env
const callbackUrl = mountingElem && mountingElem.dataset.url

createApp(App)
    .use(eVueComponents)
    .provide('Env', environment)
    .provide('URL', callbackUrl)
    .mount('#lawn-calculator')

