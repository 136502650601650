<template>
<div class="feature-card">
  <div class="feature-content">
  <ul class="features">
    <li v-for="(feature, index) in features" :key="index">
      {{feature}}
    </li>
  </ul>
  </div>
  <div class="__button-container">
    <button @click="$emit('close')">X</button>
  </div>
</div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {PropType} from "vue/dist/vue";

export default defineComponent({
  name: "FeatureCard",
  emits: ['close'],
  props: {
    features: {
      type: Array as PropType<string[]>,
      required: true
    },
  }
})
</script>

<style scoped lang="scss">
.feature-card {
  height: 100%;
  overflow: visible;
  padding: 2rem 0.5rem;
  background: #FFF;
  border: 1px solid #c1c1c1;
  box-shadow: 3px 3px 0 0 rgba(31, 33, 38, .08);
  margin-right: 10px;
  text-align: left;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .feature-content {
    height: 100%;
    overflow-y: auto;
    .features {
    margin: 0 0 0 1rem;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 2px;
      line-height: 18px;
      &:before {
        content: '\2022';
        color: #d50b1e;
        font-width: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
  }
  .__button-container {
    position: absolute;
    top: -10px;
    right: -10px;
    button {
      display: block;
      background-color: #d50b1e;
      color: #FFFFFF;
      font-weight: 700;
      text-decoration: none;
      padding: 5px 10px;
      border: none;
      margin: 0 auto;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
}
</style>
