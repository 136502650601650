<template>
<div class="e-control-panel col-lg-4 col-12" :class="{visible: visible}">
  <div class="__control-wrapper">
    <h3>Lass uns deinen Garten finden</h3>
      <p>
        Finden wir heraus, wie groß deine Rasenfläche ist:
        Gib dazu zuerst deine Adresse ein oder lasse deinen aktuellen Standort bestimmen.
      </p>

    <div class="btn-container">
      <div class="row justify-content-center" style="flex-grow: 1">
        <div class="col-12 text-center text-md-start">
          <e-web-button style="width: 100%;" @click="$emit('update:location')">Aktuellen Standort verwenden</e-web-button>
        </div>
      </div>
    </div>

    <div class="spacer">
      <span>oder</span>
    </div>

    <div class="input-container">
      <slot name="input" />
    </div>

    <div class="btn-container">
        <div class="col-xl-4 offset-xl-8 col-12 my-xl-0 my-3">
          <e-web-button style="width: 100%;" @click="$emit('update:step')" @keyup.enter="$emit('update:step')" :disabled="!placeSelected">Weiter</e-web-button>
        </div>
    </div>


  </div>
</div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";

export default defineComponent({
  name: "ControlPanel",
  props: {
    placeSelected: {
      type: Boolean as PropType<boolean>
    },
    visible: {
      type: Boolean as PropType<boolean>
    }
  }
})
</script>

<style scoped lang="scss">
/* open-sans-300 - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-italic - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans Condesed';
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.eot');
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.eot?#iefix') format('embedded-opentype'),
  url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.woff') format('woff'),
  url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.ttf') format('truetype'),
  url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.svg#open_sans_condensedbold') format('svg');
  font-weight: 700;
  font-style: normal;
}

/* iconmoon font */
@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: 300;

  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/icomoon.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/icomoon.ttf') format('truetype'); /* Safari, Android, iOS */
}
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/reboot";
$font-family-sans-serif:      'Open Sans', sans-serif;
$font-family-monospace:       'Open Sans', sans-serif;
$primary: #d50b1e;
$secondary: #000000;

.e-control-panel {
  visibility: hidden;
  display: none;
  &.visible {
    visibility: visible;
    display: block;
    box-shadow: 10px 0 20px #00000066;
    z-index: 1;
  }
  .__control-wrapper {
    padding: 24px;
    h3 {
      color: #000000;
      font-family: "Open Sans Condesed", "Helvetica", sans-serif;
    }
    .input-container {
      display: flex;
      flex-direction: column;
      margin: 2em 0;
    }
    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 2em 0;
    }
  }
}
@media (max-width: 991px) {
  .e-control-panel {
    max-height: 50vh;
    .__control-wrapper {
      .input-container {
        margin: 1em 0;
      }
      .spacer {
        display: none;
        visibility: hidden;
      }
      .btn-container {
        margin: 1em 0;
        .e-btn {
          padding: 5px 10px;
          font-size: 0.8rem;
        }
      }
    }
  }
}
</style>
