import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-586d7ae6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flip-card-inner" }
const _hoisted_2 = { class: "flip-card-front" }
const _hoisted_3 = { class: "product-card" }
const _hoisted_4 = { class: "__image-container" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "__info-container" }
const _hoisted_7 = { class: "__product-header" }
const _hoisted_8 = { class: "__item-no" }
const _hoisted_9 = { class: "__additional-infos" }
const _hoisted_10 = { class: "area" }
const _hoisted_11 = { class: "cable" }
const _hoisted_12 = { class: "__link-container" }
const _hoisted_13 = ["href"]
const _hoisted_14 = { class: "flip-card-back" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeatureCard = _resolveComponent("FeatureCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flip-card", {flip: _ctx.showFeatures}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              class: "product-image-photo",
              src: _ctx.data.productImageData.imageUrl + '-B400',
              alt: _ctx.data.title
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.data.title), 1),
            _createElementVNode("div", _hoisted_8, "Art.-Nr. " + _toDisplayString(_ctx.data.itemNo), 1),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, "max. Rasenfläche: " + _toDisplayString(_ctx.data.lawnArea.valueFormatted), 1),
              _createElementVNode("div", _hoisted_11, "Begrenzungsdraht: " + _toDisplayString(_ctx.data.cableLength.valueFormatted), 1),
              _createElementVNode("div", {
                class: "more",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFeatures = true))
              }, "Features ansehen")
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("a", {
              class: "__show-room-link",
              href: _ctx.data.showRoomUrl
            }, "Zum Produkt", 8, _hoisted_13)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_FeatureCard, {
          features: _ctx.data.pimFeatures,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFeatures = false))
        }, null, 8, ["features"])
      ])
    ])
  ], 2))
}