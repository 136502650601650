<template>
  <div v-if="loading">
    <PageLoader />
  </div>
  <div v-else>
    <div class="control-header">
      <e-web-button @click="$emit('update:stepBack')" buttonType="beta">Zurück zur Berechnung</e-web-button>
      <e-web-button @click="$emit('update:toOverview')">
        Zur Mähroboter Übersicht
      </e-web-button>
    </div>

    <div class="page-header">
      <h3>Der passende Freelexo für deine Rasenfläche</h3>
      <p v-if="lawnArea <= 1200">Die Berechnung deiner Rasenfläche hat ergeben, dass nachstehende Freelexo Modelle optimal für Deinen Rasen geeignet sind.</p>
      <p v-else>Leider können wir die von Dir kalkulierte Rasenfälche mit einem einzigen Freelexo noch nicht abdecken. Wir würden Dir daher raten mehrere Freelexos einzusetzen.</p>
    </div>

    <div v-if="roboMower.length > 0" class="product-cards-container">
    <ProductCard
        v-for="(mower, index) in roboMower"
        :key="index+'_'+mower.itemNo"
        :data="mower"
    />
    </div>

  </div>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted} from "vue";
import PageLoader from "@/components/application/PageLoader.vue";
import ProductCard from "@/components/application/ProductCard.vue";
import {RobotMowerItem} from "@/types/RoboMowerTypes";
import {RoboMowerService} from "@/services/RoboMowerService";
import {PropType} from "vue/dist/vue";

export default defineComponent({
  name: "MowerResults",
  components: {ProductCard, PageLoader},
  props: {
    lawnArea: {
      type: Number as PropType<number>,
      required: true
    }
  },
  setup(props) {
    const loading = ref<boolean>(true)
    const roboMower = ref<RobotMowerItem[]>([])

    onMounted(async () => {
      const mower = await RoboMowerService.loadAllRoboMowers(props.lawnArea)
      roboMower.value = mower
      loading.value = false
    })

    return {loading, roboMower}
  }
})
</script>

<style scoped lang="scss">
/* open-sans-300 - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-italic - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans Condesed';
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.eot');
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.eot?#iefix') format('embedded-opentype'),
  url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.woff') format('woff'),
  url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.ttf') format('truetype'),
  url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.svg#open_sans_condensedbold') format('svg');
  font-weight: 700;
  font-style: normal;
}

/* iconmoon font */
@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: 300;

  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/icomoon.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/icomoon.ttf') format('truetype'); /* Safari, Android, iOS */
}
.control-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.product-cards-container {
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 0;
}

.page-header {
  h3 {
    color: #000000;
    font-size: 1.75rem;
    font-family: "Open Sans Condesed", "Helvetica", sans-serif;
  }
}
</style>
