<template>
  <div ref="gmapContainer" class="e-gmap-container" :class="{full: showMap}"/>
</template>

<script lang="ts">
/* eslint-disable no-undef */
import {computed, defineComponent, ref, onMounted, PropType, watch} from "vue";
import {Loader} from "@googlemaps/js-api-loader";
import {Position} from "@/types/Position";

// API Key for gmaps
const GOOGLE_MAPS_API_KEY = 'AIzaSyAVfYSCsPaMCWplg6edijphoFlB_5_jqw4'

export default defineComponent({
  name: "Map",
  emits: ['update:path', 'update:area', 'update:place', 'reset:reset'],
  props: {
    inputField: {
      type: Object as PropType<HTMLInputElement>,
      required: true
    },
    userLocation: {
      type: Object as PropType<Position>
    },
    reset: {
      type: Boolean as PropType<boolean>
    },
    showMap: {
      type: Boolean as PropType<boolean>
    }
  },
  setup(props, {emit}) {
    // If user not allows to get cur. Pos. - Einhell Landau is default
    const currPos = computed<{ lat: number, lng: number } | null>(() => ({
      lat: 48.6859411,
      lng: 12.6997164
    }))
    // New Loader to load required dependencies for gmap
    const loader = new Loader({
      apiKey: GOOGLE_MAPS_API_KEY,
      libraries: ["geometry", "drawing", "places"]
    })


    // G-Map Elements
    const map = ref<google.maps.Map | null>(null)
    const autocomplete = ref<google.maps.places.Autocomplete | null>(null)
    const drawingManager = ref<google.maps.drawing.DrawingManager | null>(null)
    let markersOnMap: google.maps.Marker[] = []

    function deleteMarkers() {
      if (markersOnMap.length) {
        markersOnMap.forEach(marker => marker.setMap(null));
        markersOnMap = [];
      }
    }

    const gmapContainer = ref<HTMLElement | null>(null)
    const pathLength = ref<number>(0)
    const lawnArea = ref<number>(0)
    const polygon = ref<google.maps.Polygon | null>(null)

    onMounted(async () => {
      // Load the Map and dependencies
      await loader.load()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      map.value = new google.maps.Map(gmapContainer.value, {
        center: currPos.value,
        zoom: 21,
        mapTypeId: 'satellite',
        mapTypeControl: false
      })

      // Apply functionality to Map
      createAutocompleteField()
      createDrawingManager()

    })

    const createAutocompleteField = () => {
      //Places Search Restrictions Options
      const pacOptions: google.maps.places.AutocompleteOptions = {
        componentRestrictions: {country: ['de', 'at', 'ch']},
        fields: ["address_components", "geometry", "icon", "name"]
      }

      // Create Autocomplete text field for Places Search
      autocomplete.value = new google.maps.places.Autocomplete(props.inputField, pacOptions)
      // Event Listener for setting new Place
      autocomplete.value.addListener('place_changed', () => {
        if (autocomplete.value) {
          const newPosition = autocomplete.value.getPlace()
          if (newPosition && newPosition.geometry && newPosition.geometry.location) {
            // Set new Location to Map
            setNewLocation(newPosition.geometry.location)
            emit('update:place')
          }
        }
      })
    }

    const createDrawingManager = (reset = false) => {
      drawingManager.value = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.POLYGON,
          ]
        },
        polygonOptions: {
          editable: true,
          clickable: true,
          fillColor: '#000000',
          fillOpacity: 0.5,
          strokeColor: '#d50b1e',
          strokeWeight: 2,
          zIndex: 3
        }
      })

      if (reset) {
        drawingManager.value.setOptions({polygonOptions: {paths: null}})
      }

      if (drawingManager.value) {
        drawingManager.value.setMap(map.value)
      }

      // Add Event Listener for calculations
      google.maps.event.addListener(drawingManager.value, 'polygoncomplete', (poly: google.maps.Polygon) => {
        let path = poly.getPath()
        polygon.value = poly

        pathLength.value = google.maps.geometry.spherical.computeLength(path)
        lawnArea.value = google.maps.geometry.spherical.computeArea(path)

        if (drawingManager.value) {
          drawingManager.value.setDrawingMode(null)
        }

        google.maps.event.addListener(path, 'set_at', (index: number, newPath: google.maps.Polygon) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          path[index] = newPath
          pathLength.value = google.maps.geometry.spherical.computeLength(path)
          lawnArea.value = google.maps.geometry.spherical.computeArea(path)
        })
        google.maps.event.addListener(path, 'insert_at', () => {
          path = poly.getPath()
          pathLength.value = google.maps.geometry.spherical.computeLength(path)
          lawnArea.value = google.maps.geometry.spherical.computeArea(path)
        })
      })


    }

    const setNewLocation = (newPosition: google.maps.LatLng): void => {
      if (map.value) {
        deleteMarkers();
        const newMarker = new google.maps.Marker({
          position: newPosition,
          map: map.value
        });
        markersOnMap.push(newMarker);
        map.value.setCenter(newPosition);
        map.value.setZoom(18);
      }
    }

    //Watcher
    watch([pathLength, lawnArea], (cur, prev) => {
      if (cur[0] !== prev[0]) {
        emit('update:path', cur[0])
      }

      if (cur[1] !== prev[1]) {
        emit('update:area', cur[1])
      }
    })

    watch(props, (cur) => {
      if (cur.userLocation) {
        if (cur.userLocation && cur.userLocation.lat && cur.userLocation.lng) {
          const latLng = new google.maps.LatLng(cur.userLocation)
          setNewLocation(latLng)
        }
      }
      if (cur.reset) {
        pathLength.value = 0
        lawnArea.value = 0
        if (polygon.value && drawingManager.value) {
          polygon.value.setMap(null)
          drawingManager.value.setDrawingMode(google.maps.drawing.OverlayType.POLYGON)
          emit('reset:reset')
        }
      }
    }, {deep: true})

    return {
      gmapContainer
    }
  }
})
</script>

<style scoped lang="scss">
.e-gmap-container {
  width: calc(100% + 24px);
  height: auto;
  z-index: 0;
  margin-left: -12px;

  &.full {
    height: 80vh;
  }
}
</style>
