<template>
  <div class="e-page-loader">
    <div class="spinner-border text-primary" style="width: 5rem; height: 5rem;" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageLoader"
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/reboot";
$font-family-sans-serif:      'Open Sans', sans-serif;
$font-family-monospace:       'Open Sans', sans-serif;
$primary: #d50b1e;
$secondary: #000000;
.e-page-loader {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
