<template>
  <div class="e-no-result-panel col-lg-4 col-12">
    <div class="__control-wrapper">
      <h3>Leider kein passendes Gerät gefunden.</h3>
      <p style="margin-bottom: 0;">
        Unsere Rasenmäher empfehlen wir für bis zu 2.200 m² große Rasenflächen. Dein Garten ist größer, aber du willst trotzdem einen Einhell Rasenmäher kaufen? Hier geht's zur Produktauswahl:
        <br/>
        <a href="https://www.einhell.de/shop/de-de/garten/rasenmaeher">Rasenmäher von Einhell kaufen - mit Benzin-, Elektro- & Akku-Antrieb</a>
      </p>
      <div class="result-wrapper">
        <div class="__result-box area">
          <span class="--header error">Gartenfläche</span>
          <span class="--result error">ca. {{area.toFixed(2)}} m²</span>
        </div>
        <div class="btn-container">
          <e-web-button @click="$emit('update:stepBack')" buttonType="beta">Zurück zur Berechnung</e-web-button>
          <e-web-button @click="$emit('update:toEinhell')">
            Zur Einhell Website
          </e-web-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {PropType} from "vue/dist/vue";
import {defineComponent} from "vue";

export default defineComponent({
  name: "NoResultPanel",
  props: {
    area: Number as PropType<number>,
    length: Number as PropType<number>
  }
})
</script>

<style lang="scss" scoped>
/* open-sans-300 - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-italic - latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */ url('https://lawncalculator.einhell-live.de/assets/fonts/open-sans-v27-latin-ext-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans Condesed';
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.eot');
  src: url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.eot?#iefix') format('embedded-opentype'),
  url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.woff') format('woff'),
  url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.ttf') format('truetype'),
  url('https://lawncalculator.einhell-live.de/assets/fonts/OpenSans-CondBold-webfont.svg#open_sans_condensedbold') format('svg');
  font-weight: 700;
  font-style: normal;
}

/* iconmoon font */
@font-face {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: 300;

  src: local(''),
  url('https://lawncalculator.einhell-live.de/assets/fonts/icomoon.woff') format('woff'), /* Modern Browsers */ url('https://lawncalculator.einhell-live.de/assets/fonts/icomoon.ttf') format('truetype'); /* Safari, Android, iOS */
}
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/reboot";
$font-family-sans-serif:      'Open Sans', sans-serif;
$font-family-monospace:       'Open Sans', sans-serif;
$primary: #d50b1e;
$secondary: #000000;

.e-no-result-panel {
  box-shadow: 10px 0 20px #00000066;
  z-index: 2;
  .__control-wrapper {
    padding: 24px;
    h3 {
      color: #000000;
      font-family: "Open Sans Condesed", "Helvetica", sans-serif;
    }
    .result-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 2em 0;
      .__result-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #aaa;
        padding: 12px;
        border-radius: 3px;
        flex-grow: 1;
        .--header {
          font-weight: 700;
          &.error {
            color: var(--error);
          }
        }
        .--result {
          font-size: 1.5rem;
          &.error {
            color: var(--error);
          }
        }
        &.area.error {
          margin-right: 6px;
        }
        &.length {
          margin-left: 6px;
        }
      }
    }
    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      margin: 2em 0;
    }
  }
}
@media (max-width: 991px) {
  .e-no-result-panel {
    max-height: 50vh;
    .__control-wrapper {
      p {
        a {
          color: var(--primary);
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .result-wrapper {
        margin: 0 0 1em 0;
        .__result-box {
          padding: 5px;
          .--header {
            font-size: .9rem;
          }
          .--result {
            font-size: 1.1rem;
          }
        }
      }
      .btn-container {
        margin: 0.5em 0 1em 6px;
        .e-btn {
          padding: 5px 10px;
          font-size: 0.8rem;
          margin-top: 5px;
          width: 100%;
        }
      }
    }
  }
}
</style>
