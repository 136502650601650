import {onMounted, onUnmounted, Ref, ref, UnwrapRef} from "vue";

export function useGeolocation(): { isSupported: boolean; coords: Ref<UnwrapRef<{ latitude: number; longitude: number }>> } {
    const coords = ref({latitude: 0, longitude: 0})
    const isSupported = 'navigator' in window && 'geolocation' in navigator

    let watcher: number | null = null
    onMounted(() => {
        watcher = navigator.geolocation.watchPosition(
            position => (coords.value = position.coords)
        )
    })

    onUnmounted(() => {
        if (watcher) navigator.geolocation.clearWatch(watcher)
    })

    return { coords, isSupported}
}
