import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-285bb06f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "feature-card" }
const _hoisted_2 = { class: "feature-content" }
const _hoisted_3 = { class: "features" }
const _hoisted_4 = { class: "__button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.features, (feature, index) => {
          return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(feature), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, "X")
    ])
  ]))
}