<template>
  <div class="flip-card" :class="{flip: showFeatures}">
    <div class="flip-card-inner">
      <div class="flip-card-front">
        <div class="product-card">
          <div class="__image-container">
            <img class="product-image-photo"
                 :src="data.productImageData.imageUrl + '-B400'"
                 :alt="data.title">
          </div>
          <div class="__info-container">
            <div class="__product-header">{{ data.title }}</div>
            <div class="__item-no">Art.-Nr. {{ data.itemNo }}</div>
            <div class="__additional-infos">
              <div class="area">max. Rasenfläche: {{ data.lawnArea.valueFormatted }}</div>
              <div class="cable">Begrenzungsdraht: {{ data.cableLength.valueFormatted }}</div>
              <div class="more" @click="showFeatures = true">Features ansehen</div>
            </div>
          </div>
          <div class="__link-container">
            <a class="__show-room-link" :href="data.showRoomUrl">Zum Produkt</a>
          </div>
        </div>
      </div>
      <div class="flip-card-back">
        <FeatureCard :features="data.pimFeatures" @close="showFeatures = false"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import {PropType} from "vue/dist/vue";
import {RobotMowerItem} from "@/types/RoboMowerTypes";
import FeatureCard from "@/components/application/FeatureCard.vue";

export default defineComponent({
  name: "ProductCard",
  components: {FeatureCard},
  props: {
    data: {
      type: Object as PropType<RobotMowerItem>,
      required: true
    }
  },
  setup() {
    const showFeatures = ref<boolean>(false)

    return {showFeatures}
  }
})
</script>

<style scoped lang="scss">
.product-card {
  position: relative;
  text-align: center;
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid rgba(31, 33, 38, .25);
  box-shadow: 3px 3px 0 0 rgba(31, 33, 38, .08);
  transition: box-shadow .3s cubic-bezier(.215, .61, .355, 1), -webkit-transform .3s cubic-bezier(.215, .61, .355, 1);
  transition: transform .3s cubic-bezier(.215, .61, .355, 1), box-shadow .3s cubic-bezier(.215, .61, .355, 1);
  transition: transform .3s cubic-bezier(.215, .61, .355, 1), box-shadow .3s cubic-bezier(.215, .61, .355, 1), -webkit-transform .3s cubic-bezier(.215, .61, .355, 1);
  height: 100%;

  .__image-container {
    border-bottom: 1px solid rgba(31, 33, 38, .25);
    padding: 20px 0;

    .product-image-photo {
      max-width: 100%;
      max-height: 200px;
    }
  }

  .__info-container {
    text-align: left;
    padding: 20px;

    .__tpg-header, .__product-header {
      font-size: 14px;
      font-weight: 700;
      color: #000000;
      line-height: 28px;
    }

    .__item-no {
      font-size: 14px;
      font-weight: 700;
      color: rgba(31, 33, 38, .6);
      line-height: 28px;
    }

    .__additional-infos {
      font-size: 13px;
      font-weight: 700;
      color: rgba(31, 33, 38, .6);

      .more {
        margin: 5px 0;
        color: #d50b1e;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .__link-container {
    padding: 8px;

    .__show-room-link {
      display: block;
      background-color: #d50b1e;
      color: #FFFFFF;
      font-weight: 700;
      text-decoration: none;
      padding: 10px;
    }
  }
}

.flip-card {
  perspective: 1000px;
  width: calc(25% - 10px);
  height: 500px;
  margin-bottom: 30px;

  .flip-card-inner {
    position: relative;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;

    .flip-card-front, .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .flip-card-back {
      transform: rotateY(180deg);
    }
  }

  &.flip {
    .flip-card-inner {
      transform: rotateY(180deg);
    }
  }
}
</style>
